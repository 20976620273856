$project-color-1: #3ea89e;

$project-color-2-dark: #ffc400;
$project-color-2: #a7cb56;

$project-color-3-dark: #307c75;
$project-color-3: #3ea89e;
$project-color-3-light: #78c196;
$project-color-3-lighter: #ddeede;

$project-color-4: #ddeede;

$project-color-5: #ffffff;

$project-color-6: #333333;

$project-color-7: #d4d4d4;

$project-color-8: #c86754;

$browser-context: 16;

$enable-shadows: false;
