@import '~bootstrap/scss/bootstrap';
@import './variables';
@import './functions';
@import './helpers';

@import './fontello/css/fontello';

body {
  @extend .family-montserrat;
  font-size: $browser-context * 1px;

  background: $project-color-3-dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#App-root {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-button {
  display: none;
  height: 12px;
  border-radius: 0px;
  background-color: #aaa;
}
::-webkit-scrollbar-button:hover {
  background-color: #aaa;
}
::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b8b8b8;
}
::-webkit-scrollbar-track {
  background-color: #d8d8d8;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  background-color: #d8d8d8;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

* {
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
address * {
  user-select: text;
}

h1 {
  font-family: 'Cuprum', sans-serif;
  font-style: normal;
  font-weight: 400;

  font-size: em(58px);
  color: $project-color-1;
  line-height: 1;
}

h2 {
  font-family: 'Cuprum', sans-serif;
  font-style: normal;

  font-size: em(24px);
  color: $project-color-1;
  font-weight: 700;
  line-height: 1;
}

h4 {
  font-weight: 600;
  font-size: em(18px);
  line-height: 1;
  color: #333333;
}

p {
  color: #333333;
  font-size: em(14px);
  line-height: em(22px);
  white-space: pre-wrap;
}

.btn-secondary {
  background-color: $project-color-8;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
  letter-spacing: em(0.73077px);
  border-radius: 0;
  border: none;
  box-shadow: none !important;

  &.disabled,
  &:disabled {
    color: #ffffff;
    background-color: lighten($project-color-8, 5%);
  }

  &:not(:disabled):not(.disabled):hover {
    color: #ffffff;
    background-color: darken($project-color-8, 3%);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled).focus {
    color: #ffffff;
    background-color: darken($project-color-8, 5%);
  }
}

.btn-primary {
  background-color: $project-color-2;
  color: $project-color-5;
  font-weight: 500;
  text-align: center;
  letter-spacing: em(0.73077px);
  border-radius: 0;
  border: none;
  box-shadow: none !important;

  &.disabled,
  &:disabled {
    color: $project-color-5;
    background-color: lighten($project-color-2, 5%);
  }

  &:not(:disabled):not(.disabled):hover {
    color: $project-color-5;
    background-color: darken($project-color-2, 3%);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled).focus {
    color: $project-color-5;
    background-color: darken($project-color-2, 5%);
  }
}

.btn-light {
  background-color: transparent;
  color: $project-color-5;
  font-weight: 500;
  text-align: center;
  letter-spacing: em(0.73077px);
  border-radius: 0;
  border: none;
  box-shadow: none !important;

  &.disabled,
  &:disabled {
    color: darken($project-color-5, 5%);
    //background-color: lighten($project-color-2, 5%);
  }

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $project-color-8;
    background-color: $project-color-5;

    svg.hoverable {
      path {
        fill: $project-color-8;
        transition: fill 0.3s;
      }
    }
  }
}

.list-group {
  border-radius: 0;
  border: none;
  padding: 0;

  .list-group-item {
    padding: 0;
    border: none;
    background: transparent;
  }
}

.img-wrapper {
  width: 100%;
  height: rem(130px);
  position: relative;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    min-height: 100%;
    border: 0;
    margin: 0;
    padding: 0;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.card {
  border: none;
  background: transparent;

  .img-wrapper {
    border-bottom: 4px solid $project-color-2;
  }
}

.card-img-top {
  border-radius: 0;
}

.card-text {
  white-space: pre-wrap;
  font-weight: 400;
  font-size: em(18px);
  color: $project-color-3;
  opacity: 0.7;
}

.card-component {
  border: 0;
  border-radius: 0;
  background: transparent;
  .card-header {
    border: 0;
    border-radius: 0 !important;
    margin: 0;
    padding: rem(15px) rem(20px);
    background: $project-color-3;
    color: $project-color-3-lighter;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
  }

  .card-body {
    border: 0;
    border-radius: 0 !important;
    margin: 0;
    padding: 0;

    .card-content {
      max-height: rem(140px);
      overflow-y: auto;
    }
  }
}

.form-group {
  label {
    font-size: em(16px);
    color: $project-color-3;
  }
}

.custom-select,
.form-control {
  padding-left: rem(30px);
  padding-right: rem(30px) !important;
  color: darken($project-color-7, 25%) !important;
  border-color: $project-color-7;

  font-size: em(18px);

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius(0);

  @include box-shadow(none);

  // Placeholder
  &::placeholder {
    color: darken($project-color-7, 25%) !important;
    opacity: 1;
  }
}

.form-control {
  background-image: none !important;
}

.custom-select {
  background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 12L18 0H0L9 12Z' fill='%23DDEEDE'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/15px 15px !important;
}

.scrolling-box {
  scroll-behavior: smooth;
}

///////////////

a-scene canvas {
  background-image: linear-gradient(to top, darken(#88d3ce, 20%) 0%, #88d3ce 100%);
}

@media screen and (prefers-reduced-motion: reduce) {
  html,
  body,
  #App-root {
    scroll-behavior: auto;
  }
}
