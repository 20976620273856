@import 'variables';
@import 'functions';

@import url('https://fonts.googleapis.com/css?family=Cuprum:400,700|Montserrat:300,400,500,600');

.family-montserrat {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.family-cuprum {
  font-family: 'Cuprum', sans-serif;
  font-style: normal;
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-semi-bold {
  font-weight: 600 !important;
}
.font-size-12 {
  font-size: em(12px) !important;
}
.font-size-14 {
  font-size: em(14px) !important;
}
.font-size-15 {
  font-size: em(15px) !important;
}
.font-size-18 {
  font-size: em(18px) !important;
}
.font-size-19 {
  font-size: em(19px) !important;
}

.line-height-1 {
  line-height: 1;
}

.pre-wrap {
  white-space: pre-wrap;
}

.z-index-99 {
  z-index: 99;
}

.border-radius-8 {
  border-radius: rem(8px);
}

.border-radius-circle {
  border-radius: 50%;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: 0.75rem;
}

.top-3 {
  top: 1.125rem;
}

.top-4 {
  top: 1.5rem;
}

.top-9 {
  top: 5.125rem;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1.5rem;
}

.right-8 {
  right: 3rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-4 {
  left: 1.5rem;
}

.opacity-1 {
  opacity: 1 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.rotate-down {
  transform: rotateZ(-90deg);
}
.rotate-up {
  transform: rotateZ(90deg);
}
.rotate-right {
  transform: rotateZ(-180deg);
}

.rotate-180 {
  transform: rotateZ(180deg);
}

.color-red {
  color: $project-color-1 !important;
}
.background-red {
  background-color: $project-color-1 !important;
}
.color-red-light {
  color: $project-color-8 !important;
}
.background-red-light {
  background-color: $project-color-8 !important;
}
.color-green {
  color: $project-color-3 !important;
}
.background-green-dark {
  background-color: $project-color-3-dark !important;
}
.background-green {
  background-color: $project-color-3 !important;
}
.color-green-light {
  color: $project-color-3-light !important;
}
.background-green-light {
  background-color: $project-color-3-light !important;
}
.background-green-lighter {
  background-color: $project-color-3-lighter !important;
}
.color-yellow {
  color: $project-color-2 !important;
}
.background-yellow {
  background-color: $project-color-2 !important;
}
.color-yellow-light {
  color: $project-color-2 !important;
}
.background-yellow-light {
  background-color: $project-color-2 !important;
}
.color-zanah {
  color: $project-color-4 !important;
}
.background-zanah {
  background-color: $project-color-4 !important;
}
.color-white {
  color: $project-color-5 !important;
}
.background-white {
  background-color: $project-color-5 !important;
}

.color-dark {
  color: $project-color-6 !important;
}
.background-dark {
  background-color: $project-color-6 !important;
}

.background-concrete {
  background-color: #f3f3f3 !important;
}

.normalize-svg {
  transform: scale(0.6, 0.6);
}
