@import "../../../assets/styles/functions";

.App-footer {
  //height: 50px;
  max-width: 1440px;
  margin: 0 auto;

  &.footer-minimized {
    .navbar-footer {
      margin-left: 0;
      margin-right: 0;
      width: 150px;
    }
  }
}

.navbar-footer {
  white-space: nowrap;
  overflow-x: hidden;
  width: 100%;
  transition: width 0.5s ease;

  .navbar-nav .nav-link {
    font-weight: 500;
    color: #ffffff;
    font-size: rem(14px);
  }

  .copyright-text {
    font-weight: 300;
    color: #ffffff;
    font-size: rem(14px);
  }
}
